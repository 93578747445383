<template>
  <div>
    <SimpleHeader></SimpleHeader>
    <div class="auth-box">
      <h3 class="title mb10">{{ $t(129) }}</h3>
      <div class="tip mb20">{{ $t(182) }}</div>
      <el-form
        ref="form"
        :rules="rules"
        :model="form">
        <div>
          <div class="item">
            <div class="label mb5">{{ $t(174) }}</div>
            <el-form-item prop="certificateFrontUri">
              <img-uploader v-model="form.certificateFrontUri" bkg="front" :height="120"></img-uploader>
            </el-form-item>
          </div>
          <div class="item" v-if="this.baseMsg.certificateKind!=='PASSPORT'">
            <div class="label mb5">{{ $t(175) }}</div>
            <el-form-item prop="certificateBackendUri">
              <img-uploader v-model="form.certificateBackendUri" bkg="backend" :height="120"></img-uploader>
            </el-form-item>
          </div>
          <div class="item" v-if="configObj.isOpenIdentifyHandsUri=='1'">
            <div class="label mb5">{{ $t(176) }}</div>
            <el-form-item prop="certificateManUri">
              <img-uploader v-model="form.certificateManUri" bkg="hands" :height="120"></img-uploader>
            </el-form-item>
          </div>
        </div>
        <div class="pt20 pb10"><el-button type="primary" @click="doSubmit" :loading="loading">{{ $t(177) }}</el-button></div>
        <el-button type="text" @click="$router.go(-1)" class="el-icon-back fs16"></el-button>
        <!-- <el-button type="text" @click="$router.push('/register')">{{ $t(104) }}</el-button> -->
      </el-form>
      <div class="corpyrights">© 2017 - 2024 {{ configObj.appName }} All rights reserved</div>
    </div>
  </div>
</template>

<script>
import loginMixins from '@/mixins/login.js'
import SimpleHeader from '@/components/SimpleHeader'
import { authenticate } from '@/api'
import utils from '@/utils/misc'
import ImgUploader from '@/components/ImgUploader.vue'
export default {
  name: 'AuthVerifyTwo',
  components: { SimpleHeader,ImgUploader },
  mixins: [loginMixins],
  data() {
    return {
      form: {
        certificateFrontUri: '',
        certificateBackendUri: '',
        certificateManUri: ''
      },
      baseMsg: {},
      loading: false
    }
  },
  computed: {
    configObj() {
      return window.configObj
    },
    rules() {
      return {
        certificateFrontUri: [{ validator: (rule, value, callback) => {
          if (value === '') {
            return callback(new Error(this.$t(174)))
          }
          callback()
        } }]
      }
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    countryList() {
      return this.$store.state.countryList
    }
  },
  beforeMount() {
    if (this.countryList.length === 0) {
      this.$store.dispatch('getCountries')
    }
    const baseMsg = utils.getLs('authStepOne')
    if (baseMsg) {
      this.baseMsg = baseMsg
    } else {
      this.$router.push({name: 'AuthVerify'})
    }
  },
  methods: {
    doSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const obj = {
            realName: this.baseMsg.firstName + ' ' +this.baseMsg.lastName,
            country: this.baseMsg.country,
            certificateKind: this.baseMsg.certificateKind,
            certificateNo: this.baseMsg.certificateNo,
            certificateFrontUri: this.form.certificateFrontUri
          }
          if (this.form.certificateBackendUri) {
            obj.certificateBackendUri = this.form.certificateBackendUri
          }
          if (this.form.certificateManUri) {
            obj.certificateManUri = this.form.certificateManUri
          }
          this.loading = true
          authenticate(obj).then(res => {
            this.$router.push({name: 'Me'})
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .auth-box{
    color: #1E2329;
    width: 400px;
    margin: 10vh auto;
    .title{
      font-size: 32px;
      font-weight: 600;
    }
    .tab{
      margin: 40px 0px 20px 0px;
      color: #707A8A;
      font-size: 16px;
      .item{
        display: inline-block;
        cursor: pointer;
        padding: 8px 24px;
        margin-right: 12px;
        &.active{
          background-color: #F5F5F5;
          color: #1E2329;
          border-radius: 8px;
        }
      }
    }
    .el-input__inner{
      height: 46px;
      line-height: 46px;
    }
  }
  .corpyrights{
    position: fixed;
    bottom: 10px;
    text-align: center;
    font-size: 12px;
    width: 100%;
    left: 0;
  }
</style>