
<template>
  <el-upload
    v-loading="isLoading"
    class="img-uploader"
    action="/api/upload"
    :data="{type: '1'}"
    :headers="headers"
    :show-file-list="false"
    :on-success="handleSuccess"
    :on-error="handlerError"
    :before-upload="beforeUpload"
    :disabled="disabled"
  >
    <el-image v-if="imgUrl" :src="imgUrl" :style="{'width': width ? width+'px': 'auto', height: height?height+'px': 'auto'}" style="vertical-align:middle;">
      <div slot="placeholder" class="image-slot">
        {{ $t(186) }}<span class="dot">...</span>
      </div>
    </el-image>
    <div v-else-if="!!bkg" :class="`background-${bkg}`" class="background-img">
      <div class="flex">
        <div>
          <span v-if="desc" class="desc">{{ desc }}</span>
        </div>
      </div>
      <div class="add">
        <el-button type="text" class="el-icon-plus" style="font-size: 18px;"></el-button>
      </div>
    </div>
    <div v-else-if="isCommon" class="common">
      <div class="el-icon-plus">
      </div>
    </div>
    <div v-else>
      <div class="defaultAvatar el-icon-user">
      </div>
    </div>
  </el-upload>
</template>
<script>
import utils from '@/utils/misc'
export default {
  name: 'ImgUploader',
  props: {
    types: {
      type: Array,
      default: () => ['jpg', 'png', 'gif', 'jpeg']
    },
    value: {
      type: String
    },
    disabled: Boolean,
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    size: {
      type: Number,
      default: 20
    },
    isCommon: {
      type: Boolean,
      default: false
    },
    bkg: String,
    desc: String
  },
  data() {
    return {
      isLoading: false,
      imgUrl: this.value
    }
  },
  computed: {
    configObj() {
      return window.configObj
    },
    headers() {
      return {
        'agent-identify': utils.getLs('agentIdentify'),
        'Authorization': utils.getLs('token')
      }
    }
  },
  watch: {
    value(val) {
      this.imgUrl = val
    }
  },
  mounted() {
  },
  methods: {
    handleSuccess(res, file) {
      if (res.code === 200) {
        const addr = res.data.url
        this.imgUrl = addr
        this.isLoading = false
        this.$emit('input', addr)
        this.$emit('uploading', this.isLoading)
        this.$emit('update')
      }
    },
    handlerError() {
      this.isLoading = false
      this.$emit('uploading', this.isLoading)
      this.$message.error(this.$t(183))
    },
    beforeUpload(file) {
      const arr = file.name.split('.')
      const type = arr[arr.length - 1]
      const isValideType = this.types.includes(type)
      const isLt = file.size < this.size * 1024 * 1024
      if (!isValideType) {
        this.$message.error(this.$t(184))
        return false
      }
      if (!isLt) {
        this.$message.error(this.$t(185))
        return false
      }
      this.isLoading = isValideType && isLt
      this.$emit('uploading', this.isLoading)
      return this.isLoading
    }
  }
}
</script>
<style lang="scss" scoped>
  .defaultAvatar{
      color: #fff;
      font-size: 24px;
      width: 76px;
      height: 76px;
      line-height: 76px;
      text-align: center;
      margin-right: 40px;
      border-radius: 100%;
      background-color: #333;
  }
  .common{
      color: #999;
      font-size: 24px;
      width: 100%;
      height: 76px;
      line-height: 76px;
      text-align: center;
      border: 1px dashed $borderColor;

  }
  .background-front{
      background: url('../assets/img/front.png') no-repeat;
  }
  .background-backend{
      background: url('../assets/img/backend.png') no-repeat;
  }
  .background-hands{
      background: url('../assets/img/hands.png') no-repeat;
  }
  .background-img{
    position: relative;
    height: 120px;
    width: 200px;
    background-size: contain;
    color: #fff;
    font-weight: bold;
    .flex{
      align-items: center;
      height: 100%;
      line-height: 22px;
      justify-content: center;
    }
  }
  .add{
    width: 156px;
    height: 120px;
    line-height: 120px;
    font-size: 24px;
    border-radius: 8px;
    border-color: $borderColor;
    position: absolute;
    left: 240px;
    top: 0;
    background: $borderColor;
  }
</style>
<style lang="scss">
  .el-upload{
    width: 100%;
  }
</style>